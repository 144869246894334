import {
    $, addAction, doAction, INIT,
} from '@situation/setdesign.util';

addAction(INIT, () => {
    $('.fyc-slider').each((i, el) => {
        const $slider = $(el);
        const $images = $slider.find('.vc-single-image');
        $images.each((_, imageEl) => {
            try {
                const $image = $(imageEl);
                const $figure = $image.find('figure');
                const $curtain = $('<div class="fyc-carousel-image__curtain"></div>');
                const $overlay = $('<div class="fyc-carousel-image__overlay"></div>');
                // get image title from <img> or lazy load <span>
                const $img = $figure.find('.vc-single-image__image');
                const imageTitle = $img.attr('alt') || JSON.parse($img.attr('data-lazy-image'))?.alt;
                $figure.children('figcaption').remove();
                $overlay.append('<span class="fyc__photo-icon"></span>');

                if (imageTitle) {
                    $overlay.append(
                        `<figcaption class="vc-single-image__caption">${imageTitle}</figcaption>`,
                    );
                }

                const imageId = `fyc-carousel-image__${(Math.random() + 1)
                    .toString(36)
                    .substring(7)}`;
                $image.addClass(imageId);
                $image.append($curtain);
                $image.append($overlay);
                $image.wrap(
                    `<a class='js-modal-builder' data-modal-target='#fyc-photo' data-modal-source='.${imageId}'></a>`,
                );
            } catch (err) {
                // eslint-disable-line no-empty
            }
        });

        const $videos = $slider.find('.video-thumb');
        $videos.each((_, videoEl) => {
            const $video = $(videoEl);
            const $curtain = $('<div class="fyc-carousel-video__curtain"></div>');
            const $overlay = $('<div class="fyc-carousel-video__overlay"></div>');
            const videoId = $video.attr('data-video-id');
            const videoMeta = window?.videoCache?.[videoId];
            $overlay.append('<span class="fyc__video-icon"></span>');

            if (videoMeta) {
                $overlay.append(`<span class='video-thumb__title'>${videoMeta.title}</span>`);
            }

            $video.append($curtain);
            $video.append($overlay);
        });
    });
});

// Force the stream selector to open the first item on page load.
addAction('stream-selector/loaded', ($streamContainer) => {
    if ($streamContainer.parents('.accordion-panel').length) {
        const currentPosition = window.scrollY;
        doAction('stream-selector/switch-to-index', 0);
        setTimeout(() => {
            window.scrollTo(0, currentPosition);
        }, 0);
    }
});
