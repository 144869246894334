import { $ } from '@situation/setdesign.util';

const scrollCalendarHeading = '<h2 class="heading heading--medium text--primary-light">Scroll to find a performance</h2><p class="legend"><span class="icon text--primary"><svg><use xlink:href="#icon-star"></use></svg></span> = Best Availability</p>';
$(document).one('TicketCalendar.init', (e, TicketCalendar, $on) => {
    $on(TicketCalendar.FILTER_DEFAULTS, (defaults) => {
        defaults.update({
            // set to the $tc-theme-name sass variable
            theme: 'outsiders-theme',
            'disable-default-analytics': true,
            'accordion-month-header': { arrowSvgName: 'icon-arrow' },
            'calendar-week-header': { headerFormat: 'ddd, MMM D' },
            'calendar-list-item': { eventDateFormat: 'ddd, MMM D' },
            'scroll-calendar': { instructionText: scrollCalendarHeading },
        });
    });
});
