import {
    $, addAction, doAction, HASH_STATE_CHANGE,
} from '@situation/setdesign.util';

const adjustScrollPosition = (state) => {
    const targetID = state.current;
    if (!targetID) return;

    const $target = $(`#${targetID}`);
    if (!$target.length) return;

    const $openNav = $('.nav-menu--opened');
    if ($openNav.length) {
        doAction('hideNavMenuOverlay', $openNav);
    }

    const marginTop = parseInt($target.css('margin-top'), 10);
    if (marginTop >= 0) return;

    setTimeout(() => {
        const $headerNav = $('.header-nav');
        $('html, body').scrollTop($target.offset().top - $headerNav.outerHeight() - 20);
    }, 0);
};

addAction(HASH_STATE_CHANGE, adjustScrollPosition);
