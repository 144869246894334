import { addFilter } from '@situation/setdesign.util';

addFilter('content-slider/owl-settings', (settings) => ({
    ...settings,
    margin: 0,
    navText: [
        '<svg><use xlink:href="#icon-arrow-left"></use></svg>',
        '<svg><use xlink:href="#icon-arrow"></use></svg>',
    ],
}));
