import { addFilter } from '@situation/setdesign.util';

addFilter('media-carousel/owl-settings', (settings) => ({
    ...settings,
    margin: 25,
    navText: [
        '<svg><use xlink:href="#icon-arrow-left"></use></svg>',
        '<svg><use xlink:href="#icon-arrow"></use></svg>',
    ],
}));
